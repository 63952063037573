<template>
  <div>
    正在登录...
    {{code}}
  </div>
</template>

<script>


import { mapActions, mapGetters } from 'vuex'
import {Toast} from "vant";
import store from "@/store";
import { GetLogInfo } from "@/api/logManagement";
import {Emas} from "@/assets/js/Emas";
export default {
  name: 'Home',
  data() {
    return {
      code: '',
      flag: false,
      userInfo: {}
    }
  },

  mounted() {
    Toast.loading({
      message: '正在登录...',
      forbidClick: true,
    });
    this.getAuthCode()
    Toast.clear()


  },
  methods: {
    ...mapActions({
      dingLogin: 'user/dingLogin',
    }),

    getAuthCode() {
      let that = this
      window.dd.getAuthCode(
          {
            corpId: ""
          }
      ).then(async (result) => {
        if (result) {
          that.code = result.code
          await that.handleDingLogin()
        } else {
          Toast.fail("免密登录失败，请联系管理员！");
        }
      }).catch((err) => {
      })
    },

    async handleDingLogin() {
      await this.dingLogin(this.code)
      await store.dispatch('user/getUserInfo')
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/Home", "首页", "zzdzx.yy.gov.cn/home")
      if( await this.showUserInfoDig()) {
        await this.$router.push({
          path: '/person',
          query: {
            flag: '0'
          },
        })
      } else {
        await this.$router.push('/index')
      }
    },

    async showUserInfoDig() {
      let userInfo = store.getters['user/userInfo']
      this.userInfo = userInfo
      const { data } = await GetLogInfo({ User_ID: userInfo.user_ID })
      return !data && userInfo.type !== null;
    },

  }
}
</script>
