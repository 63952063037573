import { request } from '@/util/request'

export function GetLogInfo(params) {
  return request({
    url: '/api/Log/GetLogInfo',
    method: 'get',
    params,
  })
}

export function Log(data) {
  return request({
    url: '/api/Log/Log',
    method: 'post',
    data: data,
  })
}
